export const formTranslations = {
  "label.button.ok": "Suivant", // Default: 'Ok'
  "label.hintText.enter": "appuyez sur <strong>Entrer ↵</strong>", // Default: "",
  "label.hintText.multipleSelection": "Choisissez autant que nécessaire", // Default: "Choose as many as you like"
  "block.dropdown.placeholder": "Sélectionnez une option", // Default: "Type or select an option
  "block.dropdown.noSuggestions": "Aucune suggestion", // Default: "No Suggestions!"
  "block.shortText.placeholder": "Votre réponse", // Default: "Type your answer here"
  "block.longText.placeholder": "Votre réponse", // Default: "Type your answer here"
  "block.longText.hint":
    "<strong>Shift ⇧ + Entrer ↵</strong> pour créer un saut de ligne", // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
  "block.number.placeholder": "Votre réponse", // Default: "Type your answer here"
  "block.email.placeholder": "Votre e-mail", // Default: "Type your email here"
  "block.defaultThankYouScreen.label":
    "Merci. Vos réponses ont bien été enregistrées", // Default: "Thanks for filling this in.\n\n We will contact you soon"
  "label.hintText.key": "Touche", // Default: "Key"
  "label.progress.percent": "{{progress:percent}}% complété", // Default: "Questionnaire quotidien {{progress:percent}}% complet"
  "label.errorAlert.required": "Champs obligatoire", // Default: "This field is required!"
  "label.errorAlert.date": "Date invalide", // Default: "Invalid date!"
  "label.errorAlert.number": "Nombres uniquement", // Default: "Numbers only!"
  "label.errorAlert.selectionRequired": "Sélectionnez au moins une option", // Default: "Please make at least one selection!"
  "label.errorAlert.email": "E-mail invalide", // Default: "Invalid email!"
  "label.errorAlert.url": "URL invalide", // Default: "Invalid url!"
  "label.errorAlert.range":
    "Merci de choisir un nombre entre {{attribute:min}} et {{attribute:max}}", // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
  "label.errorAlert.minNum":
    "Merci d'entrer un nombre supérieur à {{attribute:min}}", // Default: "Please enter a number greater than {{attribute:min}}"
  "label.errorAlert.maxNum":
    "Merci d'entrer un nombre inférieur à {{attribute:max}}", // Default: "Please enter a number lower than {{attribute:max}}"
  "label.errorAlert.maxCharacters": "Nombre maximum de caractères atteint", // Default: "Maximum characters reached!"
  "label.submitBtn": "Je valide mes réponses", // Default: "Submit"
};
export const biMonthlyQuestions = [
  {
    label: "Comment évaluez-vous vos difficultés à vous endormir ?",
    id: "difficulties_falling_asleep",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Très mal",
        value: 1,
      },
      {
        label: "Mal",
        value: 2,
      },
      {
        label: "Bien",
        value: 3,
      },
      {
        label: "Très bien",
        value: 4,
      },
    ],
  },
  {
    label: "Comment évaluez-vous vos difficultés à rester endormi(e) ?",
    id: "difficulties_staying_asleep",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Très mal",
        value: 1,
      },
      {
        label: "Mal",
        value: 2,
      },
      {
        label: "Bien",
        value: 3,
      },
      {
        label: "Très bien",
        value: 4,
      },
    ],
  },
  {
    label: "Comment évaluez-vous vos problèmes de réveil trop tôt le matin ?",
    id: "problems_with_early_morning_awakenings",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Très mal",
        value: 1,
      },
      {
        label: "Mal",
        value: 2,
      },
      {
        label: "Bien",
        value: 3,
      },
      {
        label: "Très bien",
        value: 4,
      },
    ],
  },
  {
    label:
      "Jusqu’à quel point êtes-vous satisfait(e)/insatisfait(e) de votre sommeil actuel ?",
    id: "sleep_satisfaction",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Très insatisfait",
        value: 1,
      },
      {
        label: "Insatisfait",
        value: 2,
      },
      {
        label: "Satisfait",
        value: 3,
      },
      {
        label: "Très satisfait",
        value: 4,
      },
    ],
  },
  {
    label:
      "Jusqu’à quel point considérez-vous que vos difficultés de sommeil perturbent votre fonctionnement quotidien ?",
    id: "sleep_difficulties_impact_on_daily_functioning",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Pas du tout",
        value: 1,
      },
      {
        label: "Un peu",
        value: 2,
      },
      {
        label: "Assez",
        value: 3,
      },
      {
        label: "Beaucoup",
        value: 4,
      },
    ],
  },
  {
    label:
      "Vos difficultés de sommeil sont apparentes pour les autres en terme de détérioration de la qualité de votre vie ?",
    id: "visibility_of_sleep_difficulties",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Pas du tout",
        value: 0,
      },
      {
        label: "Un peu",
        value: 1,
      },
      {
        label: "Assez",
        value: 2,
      },
      {
        label: "Beaucoup",
        value: 3,
      },
    ],
  },
  {
    label:
      "Jusqu’à quel point êtes-vous inquiet(ète) / préoccupé(e) à propos de vos difficultés de sommeil ?",
    id: "concern_about_sleep_difficulties",
    includeInSleepScore: true,
    required: true,
    choices: [
      {
        label: "Pas du tout",
        value: 0,
      },
      {
        label: "Un peu",
        value: 1,
      },
      {
        label: "Assez",
        value: 2,
      },
      {
        label: "Beaucoup",
        value: 3,
      },
    ],
  },
  {
    label: "Quelle est la fréquence de votre consommation d’alcool ?",
    id: "alcohol_consumption_frequency",
    required: true,
    includeInAuditScore: true,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "1 fois par mois ou moins",
        value: 1,
      },
      {
        label: "2 à 4 fois par mois",
        value: 2,
      },
      {
        label: "2 à 3 fois par semaine",
        value: 3,
      },
      {
        label: "Au moins 4 fois par semaine",
        value: 4,
      },
    ],
  },
  {
    label:
      "Combien de verres contenant de l’alcool consommez-vous un jour typique où vous buvez ?",
    id: "alcohol_amount_per_drinking_day",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "1 ou 2",
        value: 0,
      },
      {
        label: "3 ou 4",
        value: 1,
      },
      {
        label: "5 ou 6",
        value: 2,
      },
      {
        label: "7 ou 8",
        value: 3,
      },
      {
        label: "10 ou plus",
        value: 4,
      },
    ],
  },
  {
    label:
      "Avec quelle fréquence buvez-vous six verres ou davantage lors d’une occasion particulière ?",
    id: "frequency_drinking_six_plus_units",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Au cours de l’année écoulée, combien de fois avez-vous constaté que nous n’étiez plus capable de vous arrêter de boire une fois que vous avez commencé ?",
    id: "inability_to_stop_drinking",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Au cours de l’année écoulée, combien de fois votre consommation d’alcool vous a-t-elle empêché de faire ce qui était normalement attendu de vous ?",
    id: "alcohol_impact_on_obligations",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Au cours de l’année écoulée, combien de fois avez-vous eu besoin d’un premier verre pour pouvoir démarrer après avoir beaucoup bu la veille ?",
    id: "morning_drinking_need",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Au cours de l’année écoulée, combien de fois avez-vous eu un sentiment de culpabilité ou des remords après avoir bu ?",
    id: "guilt_after_drinking",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Au cours de l’année écoulée, combien de fois avez-vous été incapable de vous rappeler ce qui s’était passé la soirée précédente parce que vous aviez bu ?",
    id: "alcohol_blackouts",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Moins d’une fois par mois",
        value: 1,
      },
      {
        label: "Une fois par mois",
        value: 2,
      },
      {
        label: "Une fois par semaine",
        value: 3,
      },
      {
        label: "Tous les jours ou presque",
        value: 4,
      },
    ],
  },
  {
    label:
      "Avez-vous été blessé ou quelqu’un d’autre a-t-il été blessé parce que vous aviez bu ?",
    id: "alcohol_related_injuries",
    includeInAuditScore: true,
    required: false,
    choices: [
      {
        label: "Non",
        value: 0,
      },
      {
        label: "Oui, mais pas au cours de l’année écoulée",
        value: 2,
      },
      {
        label: "Oui, au cours de l’année",
        value: 4,
      },
    ],
  },
  {
    label:
      "Un parent, un ami, un médecin ou un autre soignant s’est-il inquiété de votre consommation d’alcool ou a-t-il suggéré que vous la réduisiez ?",
    id: "concerns_from_others_about_drinking",
    includeInAuditScore: true,
    required: true,
    choices: [
      {
        label: "Non",
        value: 0,
      },
      {
        label: "Oui, mais pas au cours de l’année écoulée",
        value: 2,
      },
      {
        label: "Oui, au cours de l’année",
        value: 4,
      },
    ],
  },
  {
    label: "Je me sens tendu(e) ou énervé(e)",
    id: "feelings_of_tension_or_irritability",
    includeInAnxietScore: true,
    required: true,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "De temps en temps",
        value: 1,
      },
      {
        label: "Souvent",
        value: 2,
      },
      {
        label: "La plupart du temps",
        value: 3,
      },
    ],
  },
  {
    label: "Je prends plaisir aux mêmes choses qu’autrefois",
    id: "pleasure_in_former_activities",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Oui, tout autant",
        value: 0,
      },
      {
        label: "Pas autant",
        value: 1,
      },
      {
        label: "Un peu seulement",
        value: 2,
      },
      {
        label: "Presque plus",
        value: 3,
      },
    ],
  },
  {
    label:
      "J’ai une sensation de peur comme si quelque chose d’horrible allait m’arriver",
    id: "sense_of_impending_doom",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Oui, très nettement",
        value: 3,
      },
      {
        label: "Oui, mais ce n’est pas trop grave",
        value: 2,
      },
      {
        label: "Un peu, mais cela ne m’inquiète pas",
        value: 1,
      },
      {
        label: "Pas du tout",
        value: 0,
      },
    ],
  },
  {
    label: "Je ris facilement et vois le bon côté des choses",
    id: "ability_to_laugh_and_see_the_bright_side",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Autant que par le passé",
        value: 3,
      },
      {
        label: "Plus autant qu’avant",
        value: 2,
      },
      {
        label: "Vraiment moins qu’avant",
        value: 1,
      },
      {
        label: "Plus du tout",
        value: 0,
      },
    ],
  },
  {
    label: "Je me fais du souci",
    id: "constant_worry",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Très souvent",
        value: 3,
      },
      {
        label: "Assez souvent",
        value: 2,
      },
      {
        label: "Occasionnellement",
        value: 1,
      },
      {
        label: "Très occasionnellement",
        value: 0,
      },
    ],
  },
  {
    label: "Je suis de bonne humeur",
    id: "positive_mood",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Jamais",
        value: 3,
      },
      {
        label: "Rarement",
        value: 2,
      },
      {
        label: "Assez souvent",
        value: 1,
      },
      {
        label: "La plupart du temps",
        value: 0,
      },
    ],
  },
  {
    label:
      "Je peux rester tranquillement assis(e) à ne rien faire et me sentir décontracté(e)",
    id: "ability_to_relax",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Oui, quoi qu’il arrive",
        value: 0,
      },
      {
        label: "Oui, en général",
        value: 1,
      },
      {
        label: "Rarement",
        value: 2,
      },
      {
        label: "Jamais",
        value: 3,
      },
    ],
  },
  {
    label: "J’ai l’impression de fonctionner au ralenti",
    id: "feeling_of_being_slowed_down",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Presque toujours",
        value: 3,
      },
      {
        label: "Très souvent",
        value: 2,
      },
      {
        label: "Parfois",
        value: 1,
      },
      {
        label: "Jamais",
        value: 0,
      },
    ],
  },
  {
    label: "J’éprouve des sensations de peur et j’ai l’estomac noué",
    id: "feeling_of_fear_and_knot_in_stomach",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Jamais",
        value: 0,
      },
      {
        label: "Assez souvent",
        value: 1,
      },
      {
        label: "Parfois",
        value: 2,
      },
      {
        label: "Très souvent",
        value: 3,
      },
    ],
  },
  {
    label: "Je ne m’intéresse plus à mon apparence",
    id: "interest_in_physical_appearance",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Plus du tout",
        value: 3,
      },
      {
        label: "Je n’y accorde pas autant d’attention que je devrais",
        value: 2,
      },
      {
        label: "Assez il se peut que je n’y fasse plus autant attention",
        value: 1,
      },
      {
        label: "J’y prête autant d’attention que par le passé",
        value: 0,
      },
    ],
  },
  {
    label: "J’ai la bougeotte et n’arrive pas à tenir en place",
    id: "restlessness_and_inability_to_sit_still",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Oui, c’est tout à fait le cas",
        value: 3,
      },
      {
        label: "Un peu",
        value: 2,
      },
      {
        label: "Pas tellement",
        value: 1,
      },
      {
        label: "Pas du tout",
        value: 0,
      },
    ],
  },
  {
    label: "Je me réjouis d’avance à l’idée de faire certaines choses",
    id: "positive_anticipation",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Autant qu’avant",
        value: 0,
      },
      {
        label: "Un peu moins qu’avant",
        value: 1,
      },
      {
        label: "Bien moins qu’avant",
        value: 2,
      },
      {
        label: "Presque jamais",
        value: 3,
      },
    ],
  },
  {
    label: "J’éprouve des sensations soudaines de panique",
    id: "sudden_sensations_of_panic",
    includeInAnxietyScore: true,
    required: true,
    choices: [
      {
        label: "Vraiment très souvent",
        value: 0,
      },
      {
        label: "Assez souvent",
        value: 1,
      },
      {
        label: "Pas souvent",
        value: 2,
      },
      {
        label: "Jamais",
        value: 3,
      },
    ],
  },
  {
    label:
      "Je peux prendre plaisir à un bon livre ou à une bonne émission de radio ou de télévision",
    id: "ability_to_enjoy_cultural_activities",
    includeInDepressionScore: true,
    required: true,
    choices: [
      {
        label: "Souvent",
        value: 0,
      },
      {
        label: "Parfois",
        value: 1,
      },
      {
        label: "Rarement",
        value: 2,
      },
      {
        label: "Très rarement",
        value: 3,
      },
    ],
  },
];
