import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./styles.css";
import { createClient } from "@supabase/supabase-js";
import { biMonthlyQuestions, formTranslations } from "./constants";

const getBlocks = (questionsList) => {
  let blocks = [];
  for (let question of questionsList) {
    blocks.push({
      name: "multiple-choice",
      id: question.id,
      attributes: {
        required: question.required,
        multiple: false,
        verticalAlign: true,
        label: question.label,
        choices: question.choices,
      },
    });
  }
  return blocks;
};
const queryParameters = new URLSearchParams(window.location.search);
const patient_id = +queryParameters.get("patient_id");
// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://lvykkopdvnxbqupaivhe.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2eWtrb3Bkdm54YnF1cGFpdmhlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg5ODMzMjIsImV4cCI6MjAyNDU1OTMyMn0.7Y7CC_FwB58ETbhA6jDszC70oxTm0O10ANg8O8vge4o"
);

registerCoreBlocks();
const App = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          messages: formTranslations,
          blocks: getBlocks(biMonthlyQuestions),
        }}
        beforeGoingNext={({ currentBlockId, goToBlock, answers, goNext }) => {
          // Check if the current block is the 'jump' question
          console.log(answers["alcohol_consumption_frequency"]["value"])
          if (
            currentBlockId === "alcohol_consumption_frequency" &&
            answers["alcohol_consumption_frequency"]["value"] !== undefined &&
            answers["alcohol_consumption_frequency"]["value"][0] === 0
          ) {
            console.log("Found out");
            console.log(answers["alcohol_consumption_frequency"]["value"][0]);
            // Jump to the 'jumped' question
            goToBlock("concerns_from_others_about_drinking");
          }
          goNext();
          return true; // Allow default navigation
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          console.log(data.answers);
          setTimeout(async () => {
            const sleepQuestionIds = biMonthlyQuestions
              .filter((question) => question.includeInSleepScore === true)
              .map((question) => question.id);
            const sleepAnswers = Object.fromEntries(
              Object.entries(data.answers).filter(([key, value]) =>
                sleepQuestionIds.includes(key)
              )
            );
            const sleepScore = Object.values(sleepAnswers).reduce(
              (acc, val) => acc + +val.value,
              0
            );

            const auditQuestionIds = biMonthlyQuestions
              .filter((question) => question.includeInAuditScore === true)
              .map((question) => question.id);
            const auditAnswers = Object.fromEntries(
              Object.entries(data.answers).filter(([key, value]) =>
                auditQuestionIds.includes(key)
              )
            );
            const auditScore = Object.values(auditAnswers).reduce(
              (acc, val) => acc + +val.value,
              0
            );
            const anxietyQuestionIds = biMonthlyQuestions
              .filter((question) => question.includeInAnxietyScore === true)
              .map((question) => question.id);
            const anxietyAnswers = Object.fromEntries(
              Object.entries(data.answers).filter(([key, value]) =>
                anxietyQuestionIds.includes(key)
              )
            );
            const anxietyScore = Object.values(anxietyAnswers).reduce(
              (acc, val) => acc + +val.value,
              0
            );
            const depressionQuestionIds = biMonthlyQuestions
              .filter((question) => question.includeInDepressionScore === true)
              .map((question) => question.id);
            const depressionAnswers = Object.fromEntries(
              Object.entries(data.answers).filter(([key, value]) =>
                depressionQuestionIds.includes(key)
              )
            );
            const depressionScore = Object.values(depressionAnswers).reduce(
              (acc, val) => acc + +val.value,
              0
            );
            console.log(
              await supabase.from("bi_monthly_questions").insert({
                ...biMonthlyQuestions.reduce((obj, cur) => {
                  // Check if data.answers[cur.id] exists and has a value property
                  if (data.answers[cur.id] && data.answers[cur.id].value) {
                    // Use the value if it exists
                    obj[cur.id] = data.answers[cur.id].value[0];
                  }
                  return obj;
                }, {}),
                patient_id: patient_id,
                sleep_score: sleepScore,
                anxiety_score: anxietyScore,
                depression_score: depressionScore,
                audit_score: auditScore,
              })
            );
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </div>
  );
};
export default App;
